@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media screen and (min-width:660px) {
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(8, 8, 8, 0.3); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 9px;
  -webkit-box-shadow: inset 0 0 6px rgb(8, 8, 8,0.5); 
}
}

.center {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: #0e8581;
}
.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, cyan, #fff);
  margin: 10px;
  -webkit-animation: wave 1s linear infinite;
          animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.wave:nth-child(3) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.wave:nth-child(4) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.wave:nth-child(5) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.wave:nth-child(6) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.wave:nth-child(7) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.wave:nth-child(8) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.wave:nth-child(9) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.wave:nth-child(10) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

@-webkit-keyframes wave {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes wave {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}


*{
    margin: 0;
    padding: 0;
}
.container{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:row;
            flex-direction:row;
    place-items: center;
    height:100vh
}
.home-side-left{
    position:relative;
    background: linear-gradient(180deg, #0C7976 0%, #09BDB8 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -5px -5px 5px #75F5F5;
    width:40%;
    -webkit-order: 0;
            order: 0;
    place-items: center;
    height:100%;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
            justify-content:center;
}
.video-box{
    height: 350px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
            justify-content:center;
    width: 55%;
    min-width: 300px;
    box-shadow: -5px -5px 4px #7ad6d3, 5px 5px 4px #11807C;
    border-radius: 27px;
    z-index: 0;
    overflow: hidden;
}
#modal-home
{
    width: 400px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    background: linear-gradient(110.08deg, rgba(31, 59, 68, 0.4) 4.89%, rgba(31, 59, 68, 0.1) 93.4%)!important;
    border-top: 1px solid rgba(255, 255, 255, 0.5)!important;
    border-left: 1px solid rgba(255, 255, 255, 0.5)!important;
    border-bottom: 1px solid rgba(4, 94, 97, 0.5)!important;
    border-right: 1px solid rgba(4, 94, 97, 0.5)!important;
    -webkit-backdrop-filter: blur(20px)!important;
            backdrop-filter: blur(20px)!important;
    border-radius: 20px!important;
    box-shadow: 0 0 20px rgb(43, 42, 42);
    color: white;
}
#copy-to-clipboard-icon{
    color:#0e8581;
    font-size: 1.5em;
}
#copy-to-clipboard-icon:hover{
    cursor: pointer;
}
.video{
    -webkit-flex-grow: 3;
            flex-grow: 3;
    border-radius: 27px 27px 0 0 ;
    width: 100%;
    overflow: hidden;
}
.video-off-box{
    width: 100%;
    border-radius: 27px 27px 0 0;
    -webkit-flex-grow: 3;
            flex-grow: 3;
    height: 100%;
    background-color: #0a6c79;
    box-shadow:4px 4px 20px #044750b6 inset;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
            justify-content:center;
    place-items: center;
    color: #fff;
    font-family: ubuntu;
}

.video-off{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px dashed #fff;
    padding:5px;
    color: #fff;
    -webkit-filter: drop-shadow(0px 4px 5px #000000);
            filter: drop-shadow(0px 4px 5px #000000);
    font-size: 20px;
}
.footer-options-video{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction:row;
            flex-direction:row;
    background: #0C7976;
    box-shadow: inset -6px -6px 6px 1px rgba(16, 61, 59, 0.5), inset 6px 0px 6px 1px rgba(16, 61, 59, 0.5);
    border-radius: 0px 0px 27px 27px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-justify-content:space-evenly;
            justify-content:space-evenly;
    place-items: center;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    
    border-top: 2px dashed #fff;
}
.video-set-buttons{
    color: #fff;
    -webkit-filter: drop-shadow(0px 4px 5px #000000);
            filter: drop-shadow(0px 4px 5px #000000);
    font-size: 25px
}
.mountainBackground{
    width: 100%;
    position: absolute;
    bottom:0;
    z-index: 0;
}
.logo-home{
    position: absolute;
    top:10px;
    left: 10px;
    width: 50px;
    -webkit-filter: drop-shadow(0px 4px 5px #3a3a3a);
            filter: drop-shadow(0px 4px 5px #3a3a3a)
}
.footer-options-video svg:hover{
    cursor: pointer;
}
.home-side-right{
    position:relative;
    background-color: #00fff7;
    width:60%;
    -webkit-order: 1;
            order: 1;
    place-items: center;
    height:100%;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
            justify-content:center;
}
.entry-box{
    height: 350px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:space-evenly;
            justify-content:space-evenly;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    width: 50%;
    min-width: 300px;
    border: 2px solid #C4BCBC;
    box-sizing: border-box;
    border-radius: 27px;
    z-index: 0; 
    position: relative;  
}
.avatar-home{
    width: 100px;
    position: absolute;
    top: -60px;
    border: 1px dashed black;
    border-radius:50%;
    padding:10px;
    background-color: #fff;
}
.home-bottom-vector{
    width: 30%;
    position: absolute;
    bottom: 5px;
}
.email-input,.name-input{
    width: 80%;
    border: none;
    outline: none;
    border-bottom: 1px solid #C4BCBC;
    padding:10px;
    font-family: Ubuntu;
}
.home-entry-options
{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:row;
            flex-direction:row;
    place-items: center;
    width: 100%;
    -webkit-justify-content:space-around;
            justify-content:space-around;
}
.home-entry-buttons{
    padding:10px;
    background-color:#0e8581;
    color:#fff;
    border: none;
    border-radius: 27px;
    box-shadow: 2px 2px 5px #03524e , 
    -2px -2px 5px #fff;
    font-weight: 800;
    width: 40%;
    font-family: "Ubuntu";
}
.home-entry-buttons-disabled{
    background-color: #aaa!important;
    color: #555!important;
}
.home-entry-buttons:hover{
    cursor: pointer;
}
@media screen and (max-width:660px){
    .container{
        display:-webkit-flex;
        display:flex;
        -webkit-flex-direction:column;
                flex-direction:column;
        width:100%;
        height:auto;
        -webkit-align-self: center;
                align-self: center;
    }
    .home-side-left{
        position:relative;
        background: linear-gradient(180deg, #0C7976 0%, #09BDB8 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -5px -5px 5px #75F5F5;
        width:100%;
        place-items: center;
        display:-webkit-flex;
        display:flex;
        -webkit-flex-direction:column;
                flex-direction:column;
        -webkit-justify-content:center;
                justify-content:center;
        height:500px;
    }
    .home-side-right
    {
        padding-top: 100px;
        width: 100%;
    }
    .logo-home{
        left: 20px;
    }
    .video-box{
        width: 90%;
        min-width: auto;
        height:60%;
    }
    .entry-box{
        width: 90%;
        min-width: auto;
    }
    .home-bottom-vector{
        width: 50%;
        position: relative;
        bottom: auto;
        margin: 10px;
    }
    #modal-home
    {
        width: 80%;
    }
}

.chat-header{
    font-family: Ubuntu;
    color: #fff;
    background-color:#10a19f;
    margin: 0;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.chat-outer-wrapper{
    -webkit-flex-grow: 2;
            flex-grow: 2;
    overflow: auto;
}
.chat-wrapper{
    margin: 5px;
    background-color: #333;
    border-radius: 12px;
    padding: 5px;
    width:80%;
    direction: ltr;
    -webkit-align-self: flex-start;
            align-self: flex-start;
}
.chat-wrapper-user{
    -webkit-align-self: flex-end;
            align-self: flex-end;
}
.chat-member-name{
    font-weight: 700;
    color:#086d6b;
    text-align: left;
}
.chat-message{
    color: rgb(255, 255, 255);
    text-align: left;
}
.chat-pro-sidebar-footer{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    padding: 10px;
    -webkit-justify-content:space-evenly;
            justify-content:space-evenly;
    
}
.chatEntry{
    outline:none;
    border:none;
    background-color: transparent;
    text-align: left;
    color: white;
}
.chat-pro-sidebar-content
{
    overflow: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    direction: ltr;
}
.chat-opener{
    color: #10a19f;
    width: 40px;
    height:40px;

}
.chat-opener:hover
{
    cursor: pointer;
}
.chat-badge{
    position:absolute;
    right: 15px;
    top: 15px;
    width: 15px !important;;
    height: 15px !important;
    border-radius:50%;
    background-color:#65d4d3;
}
.chat-pro-sidebar{
    text-align:left!important;
}
@media screen and (max-width:660px)
{
    .chat-section{
        width:100%;
    }
    .chat-pro-sidebar{
        position: absolute !important;
        right: 0 !important;
        text-align:left!important;
        
    }
}
*{
    font-family: ubuntu;
    outline: none;
}
.meet-parent{
 
    background:rgb(43, 42, 42);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction:row;
            flex-direction:row;
    overflow-x: hidden;
    position: relative;
}
.meet-teams-logo:hover{
    cursor: pointer;
}
.meet-outer-layout{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
}
.members-with-config{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    place-items: center;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    height: 100%;
    -webkit-justify-content:space-evenly;
            justify-content:space-evenly;
}
@-webkit-keyframes meet-slide-left {
    0%{
        width: 100%;
    }
    100%{
        width: 80%;
    }
}
@keyframes meet-slide-left {
    0%{
        width: 100%;
    }
    100%{
        width: 80%;
    }
}
@-webkit-keyframes meet-slide-right {
    0%{
        width: 80%;
    }
    100%{
        width: 100%;
    }
}
@keyframes meet-slide-right {
    0%{
        width: 80%;
    }
    100%{
        width: 100%;
    }
}
.meet-top-option-box{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content:space-between;
            justify-content:space-between;
    margin: 20px;
    width: 20%;
}
.meet-top-option-box div,.meet-top-option-box svg
{
    width: 40px;
    height:40px;
}
.screen-share{
    color: #10a19f;
    width: 40px;
    height:40px;
}
.screen-share:hover
{
    cursor: pointer;
}
.screen-share-box {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 70%;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    place-items: center;
    -webkit-justify-content:center;
            justify-content:center;
}
.meet_And_screen_share{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 85%;
    -webkit-justify-content:center;
            justify-content:center;
    place-items: center;

}
#share-screen-user{
    width: 98%;
    height: 98%;
    box-shadow: 0 0 20px 10px rgb(43, 42, 42);
}
.meet-icons{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    height: 15%;
}
.meet-icons img{
    width: 50px;
    height: 50px;
    margin: 20px;
}
.screen-share-disabled:hover{
    cursor:not-allowed;
}
.members-row{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    place-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    overflow: auto;
    width: 100%;
    height: 80%;
}
.camera-off-member{
    margin: 20px;
    width: 80%;
    max-width: 400px;
    height: 200px;
    background: linear-gradient(110.08deg, rgba(31, 59, 68, 0.4) 4.89%, rgba(31, 59, 68, 0.1) 93.4%);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(31, 7, 116, 0.5);
    border-right: 1px solid rgba(31, 7, 116, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 27px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    place-items: center;
    box-shadow: 0 0 20px rgb(43, 42, 42);
    position: relative;
}
.member-name-initial{
    width:90px;
    height:90px;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:center;
            justify-content:center;
    place-items: center;
    color: #ffffff;
    font-size: 2rem;
    box-shadow:5px 5px 10px rgb(51, 50, 50),-1px -1px 5px #ffffff;
}
.member-name
{
    color:#fff;
    position: absolute;
    bottom: 5%;
    right:5%;
    text-shadow: 0px 0px 15px rgb(0, 0, 0);
}
.user-video-box{
    width: 80%;
    max-width: 400px;
    height: 200px;
    box-sizing: border-box;
    box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.5);
    border-radius: 27px;
    object-fit: cover;
    margin: 20px;
    position: relative;
}
.meet-options{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction:row;
            flex-direction:row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    place-items: center;
    background: linear-gradient(110.08deg, rgba(31, 59, 68, 0.4) 4.89%, rgba(31, 59, 68, 0.1) 93.4%);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(31, 7, 116, 0.5);
    border-right: 1px solid rgba(31, 7, 116, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    max-width: 300px;
    width: 80%;
    padding:10px;
    border-radius: 27px;
    -webkit-align-self: center;
            align-self: center;
    box-shadow: 0 0 20px rgb(43, 42, 42);
}
.meet-controllers
{
    color: #fff;
    font-size: 1.8em;
}
.disconnect-meet{
    background-color: rgb(224, 68, 68);
    width: 43px;
    height: 43px;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    place-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    box-shadow: 0 0 5px rgb(65, 63, 63);
}
.meet-controllers:hover{
    cursor: pointer;
}
.Modal-Box-Meet-options{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    place-items: center;
}
.meet-modal-controllers{
    font-size:1.4em
}
.ReactModal__Content
{
    width: 400px;
    height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.name-input-meet{
    width: 80%;
    border: none;
    outline: none;
    border-bottom: 1px solid #C4BCBC;
    padding:10px;
    font-family: Ubuntu;
    margin: 10px;
    font-size: 1.1em;
}
.Modal-Box-Meet-footer{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    place-items: center;
}
.other-options{
    position: relative;
}
.dropdown-meet{
    list-style: none;
    background-color: rgb(11, 153, 146);
    position: absolute;
    width: 200px;
    z-index: 2;
    left: -120px;
    display: none;
    top: 30px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px #2b2a2a;
}
.dropdown-meet li{
    padding: 10px;
    color: #fff;
    border-bottom: 1px solid #C4BCBC;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.dropdown-meet li:hover{
    background-color: #0b5f4d;
    cursor: pointer;
}
.dropdown-meet li:last-child
{
    border-bottom: none;
}
.background-switcher{
    width: 30%!important;
}
.other-options:hover{
    cursor: pointer;
}
.other-options:hover .dropdown-meet{
    display: block;
}
.Transcript-down-position
{
    background-color: rgba(34, 34, 34, 0.822);
    color: #fff;
    font-size: 1.8em;
    padding: 5px;
    margin: 2px;
    text-align: center;
}
@media screen and (max-width:660px) {
    .members-row{
        width: 100%;
        overflow:auto;
        height:100%;
        -webkit-order: 2;
                order: 2;
    }
    .members-with-config{
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        width: 100%!important;
        overflow: hidden;
    }
    .meet-options{
        margin: 20px;
        -webkit-order: 1;
                order: 1;
    }
    .screen-share{
        color: #10a19f;
        width: 40px;
        height:40px;
    }
    .camera-off-member{
        width: 90%!important;
    }
    .ReactModal__Content
    {
        width: 70%;
    }
    .meet-top-option-box{
        width:50%
    }
    .meet_And_screen_share{
        -webkit-flex-direction: column;
                flex-direction: column;
        height: 85%;
        position:relative;
    }
    .screen-share-box{
        width:100%;
        height: auto;
        z-index: 1;     
    }
    .user-video-box{
        width: 90%;

    }
}

.transcript-header{
    font-family: Ubuntu;
    color: #fff;
    background-color:#10a19f;
    margin: 0;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.transcript-outer-wrapper{
    -webkit-flex-grow: 2;
            flex-grow: 2;
    overflow: auto;
}
.transcript-wrapper{
    margin: 5px;
    /* background-color: #C4BCBC; */
    border-radius: 12px;
    padding: 5px;
    width:80%;
    direction: ltr;
    -webkit-align-self: flex-start;
            align-self: flex-start;
}
.transcript-wrapper-user{
    -webkit-align-self: flex-end;
            align-self: flex-end;
}
.transcript-member-name{
    font-weight: 700;
    color:#086d6b;
    text-align: left;
}
.transcript-message{
    color: rgb(255, 255, 255);
    text-align: left;
}
.transcript-pro-sidebar-footer{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    padding: 10px;
    -webkit-justify-content:space-evenly;
            justify-content:space-evenly;
    
}
.transcript-pro-sidebar-content
{
    overflow: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    direction: ltr;
}
.transcript-opener{
    color: #10a19f;
    width: 40px;
    height:40px;

}
.transcript-opener:hover
{
    cursor: pointer;
}
.transcript-badge{
    position:absolute;
    right: 15px;
    top: 15px;
    width:15px;
    height: 15px;
    border-radius:50%;
    background-color:#65d4d3;
}
.transcript-pro-sidebar{
    text-align:left!important;
}
@media screen and (max-width:660px)
{
    .transcript-section{
        width:100%;
    }
    .transcript-pro-sidebar{
        position: absolute !important;
        left: 0 !important;
        text-align:left!important;
        
    }
}
.sign-in-parent{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    place-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    height: 100vh;
     background:url(/static/media/hand-painted-mountains.b79ec315.jpg);
     background-size: cover;
    /* background-size: 400% 400%;
    animation: gradient 15s ease infinite; */
}
.forgot-password{
    color: #03524e;
    text-decoration: none;
}
@-webkit-keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
.signin-logo-box{
    -webkit-align-self: flex-start;
            align-self: flex-start;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    place-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.logo-signin{
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
.signin-head{
    margin-top: 40px;
    color: teal;
}
.signin-entry-box{
    height: 350px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:space-evenly;
            justify-content:space-evenly;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    width: 35%;
    min-width: 300px;
    box-sizing: border-box;
    border-radius: 27px;
    z-index: 0; 
    position: relative;  
    -webkit-align-self: center;  
            align-self: center;
    background-color: #00ff52;
    border: 1px solid rgb(0 28 64);
    box-shadow: 0 0 27px rgb(59, 59, 59);
}
.avatar-signin{
    width: 100px;
    position: absolute;
    top: -60px;
    border: 2px dashed #005b5f;
    border-radius:50%;
    padding:10px;
    background-color: rgb(0, 236, 185);
}
.signin-bottom-vector{
    width: 20%;
    /* position: absolute;
    bottom: 5px; */
}
.email-input,.name-input,.password-input{
    width: 80%;
    border: none;
    outline: none;
    border-bottom: 1px solid #c5b9b9;
    padding:10px;
    font-family: Ubuntu;
}
.signin-entry-options
{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:row;
            flex-direction:row;
    place-items: center;
    width: 100%;
    -webkit-justify-content:space-around;
            justify-content:space-around;
}
.signin-entry-buttons{
    padding:10px;
    background-color:#0e8581;
    color:#fff;
    border: none;
    border-radius: 27px;
    box-shadow: 2px 2px 5px #03524e , 
    -2px -2px 5px #fff;
    font-weight: 800;
    width: 40%;
    font-family: "Ubuntu";
}
.signin-entry-buttons:hover{
    cursor: pointer;
}

@media screen and (max-width:660px) {
    .signin-entry-box{
        height: 50%;
    }
    .signin-bottom-vector{
        width: 30%;
    }
}
.sign-up-parent{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    place-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    height: 100vh;
     background:url(/static/media/hand-painted-mountains.b79ec315.jpg);
     background-size: cover;
    /* background-size: 400% 400%;
    animation: gradient 15s ease infinite; */
}
@-webkit-keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
.signup-logo-box{
    -webkit-align-self: flex-start;
            align-self: flex-start;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    place-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.logo-signup{
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
.signup-head{
    margin-top: 40px;
    color: teal;
}
.signup-entry-box{
    height: 450px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:space-evenly;
            justify-content:space-evenly;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    width: 35%;
    min-width: 300px;
    box-sizing: border-box;
    border-radius: 27px;
    z-index: 0; 
    position: relative;  
    -webkit-align-self: center;  
            align-self: center;
    background-color: #00ff52;
    border: 1px solid rgb(0 28 64);;
    box-shadow: 0 0 27px rgb(59 59 59);
}
.avatar-signup{
    width: 100px;
    position: absolute;
    top: -60px;
    border: 2px dashed #005b5f;
    border-radius:50%;
    padding:10px;
    background-color: rgb(0, 236, 185);
}
.signup-bottom-vector{
    width: 20%;
    /* position: absolute;
    bottom: 5px; */
}
.email-input,.name-input,.password-input{
    width: 80%;
    border: none;
    outline: none;
    border-bottom: 1px solid #c5b9b9;
    padding:10px;
    font-family: Ubuntu;
}
.signup-password-format{
    width: 80%;
}
.signup-entry-options
{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:row;
            flex-direction:row;
    place-items: center;
    width: 100%;
    -webkit-justify-content:space-around;
            justify-content:space-around;
}
.signup-entry-buttons{
    padding:10px;
    background-color:#0e8581;
    color:#fff;
    border: none;
    border-radius: 27px;
    box-shadow: 2px 2px 5px #03524e , 
    -2px -2px 5px #fff;
    font-weight: 800;
    width: 40%;
    font-family: "Ubuntu";
}
.signup-entry-buttons:hover{
    cursor: pointer;
}

@media screen and (max-width:660px) {
    .signup-entry-box{
        height: 60%;
    }
    .signup-bottom-vector{
        width: 30%;
    }
}
.main-component-parent{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100vh;
}
.main-component-header{
    background-color: rgb(0 155 110);
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    color:white;
    place-items: center;
    height: 10%;
}
.main-component-content{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 100%;
    width: 100%;
    height: 90%;
}
.header-logo-box{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    place-items: center;
}
.room-join-options-hover{
    display: -webkit-flex;
    display: flex;
    place-items: center;
    height: 100%;
    
    font-size: 2em;
    position: relative;
}
.top-header-side-options{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    place-items: center;
    width: 10%;
}
.top-header-side-options svg{
    font-size: 30px;
}
.logo-main-component{
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
.main-chat-pro-sidebar{
    width: 22%;
    min-width: auto;
    z-index: 0;
}
.main-chat-pro-header{
    padding: 10px;
    background-color: rgb(0 101 33);
    color: rgb(0, 195, 255);
    height: 60px!important;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}
.main-chat-pro-content{
    overflow: auto;
    height: 100%;
    background: linear-gradient(-45deg, rgb(0 145 126), rgb(0 68 100));
    background-size: cover;

}
#selectedRoom{
    background-color: #333;
    color: #fff;
}
.sidebar-room{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    place-items: center;
    padding: 10px;
    font-weight: 700;
}
.room-initial
{
    width: 50px;
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    place-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 50%;
    background-color: rgb(18, 97, 172);
    color: white;
    margin-right: 10px;
}
.sidebar-room:hover{
    cursor: pointer;
    background-color: rgb(228, 222, 222);
}
.main-chat-chats-box{
    width: 100%;
    height: 100%;
    z-index: 0;
}
.main-chat-chats-box-header{
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    place-items: center;    
    height: 60px;
    box-sizing: border-box;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background-color: #fff;
    box-shadow: 0px 10px 5px #000;
}
.main-chat-chats-box-content{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow-y: auto;
}
.room-detail{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    place-items: center;
}
.join-meet-main-chat{
    /* padding:10px;
    background-color:#0e8581;
    color:#fff;
    border: none;
    border-radius: 27px;
    box-shadow: 2px 2px 5px #03524e , 
    -2px -2px 5px #fff;
    font-weight: 800;
    width: 20%;
    font-family: "Ubuntu";
    min-width: 95px; */
    font-size: 30px;
    color: #333;
}
.main-chat-chats-box-content{
    background: linear-gradient(45deg,#00bf2d,#008cac);
    background-size: cover;
    z-index: 0;
}
.room-name h5{
    font-size: 10px;
}
.main-chat-chats-box-footer{
    padding: 20px;
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    place-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.main-chat-chats-box-footer input{
    color: black;
    width: 90%;
}
.room-join-options-hover:hover .room-join-options{
    display: block;
    cursor: pointer;
}
.room-join-options{
    width: 150px;
    position: absolute;
    left: -150px;
    top: 8px;
    background-color: rgb(8, 139, 100);
    list-style: none;
    z-index: 1011;
    box-shadow: 0 0 5px #333;
    border-radius: 12px;
    overflow: hidden;
    display: none;
}
.room-options-chat{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    place-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 10%;
}
.background-switcher-chat{
    width: 40px;
    height: 20px;
}
.background-switcher-chat .react-switch-bg
{
    width: 100%!important;
    height: 100%!important;
}
.background-switcher-chat .react-switch-handle
{
    height: 19px!important;
    width: 19px!important;
}
.room-join-options li{
border-bottom: 1px solid #aaa;
font-size: 0.5em;
padding: 10px;
display: -webkit-flex;
display: flex;
-webkit-flex-direction: row;
        flex-direction: row;
place-items: center;
-webkit-justify-content: space-between;
        justify-content: space-between;
}
.room-join-options li:last-child{
    border-bottom: none;
}
.room-join-options li:hover{
    background-color: rgb(4, 97, 69);
}
.modal-input-main-component{
    width: 80%;
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid #333;
    padding: 10px;
}
#modal-main-component{
    z-index: 3;
    background: linear-gradient(110.08deg, rgba(0, 255, 47, 0.4) 4.89%, rgba(37, 248, 255, 0.1) 93.4%)!important;
    border-top: 1px solid rgba(22, 239, 170, 0.5)!important;
    border-left: 1px solid rgba(255, 255, 255, 0.5)!important;
    border-bottom: 1px solid rgba(4, 94, 97, 0.5)!important;
    border-right: 1px solid rgba(4, 94, 97, 0.5)!important;
    -webkit-backdrop-filter: blur(20px)!important;
            backdrop-filter: blur(20px)!important;
    border-radius: 20px!important;
    color: #fff;
    box-shadow: 0 0 10px #333;

}

.join-room-main-chat{
    padding:10px;
    background-color:#00164d;
    color:rgb(0, 253, 72);
    border: none;
    border-radius: 27px;
    box-shadow: 2px 2px 5px #023a37 , 
    -1px -1px 5px rgb(187, 183, 183);
    font-weight: 800;
    width: 20%;
    font-family: "Ubuntu";
    min-width: 95px;
}
.join-room-main-chat:hover{
    cursor: pointer;
}
.no-room-selected{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    place-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    background-image: url('https://s.walldump.com/wallpapers/walldump-9e0JmevNo.jpg');
    background-size: cover;
    color: #fff;
    text-shadow: 0px 0px 5px #000;
}
.sidebar-room-chat{
    -webkit-align-self: flex-start;
            align-self: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    place-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    min-width: 150px;
    margin: 5px;
    max-width: 50%;
}
#shiftChat{
    -webkit-align-self: flex-end!important;
            align-self: flex-end!important;
    
    -webkit-justify-content: flex-end;
    
            justify-content: flex-end;
}
#shiftChat .room-initial-chat{
    margin-left: 10px;
    margin-right: 0;
    -webkit-order: 1;
            order: 1;
}
.chat-user{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* border-radius: 30px; */
    background-color: rgba(51, 51, 51, 0.726);
    border-radius: 12px;
    color: white;
    padding: 7px;
    min-width: 150px;
    
}
.chat-user-name{
    color: #0f9b9b;
}
.chat-user-message{
    word-wrap: break-word;
    width: 100%;
}
#shiftChat >.chat-user > h4,#shiftChat > .chat-user > div{
    -webkit-align-self: flex-end;
            align-self: flex-end;
}
.room-initial-chat{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    place-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #fff;
    font-weight: 700;
    margin: auto 10px;
}
.chat-entry{
    outline: none;
    border: none;
    border-bottom: 1px solid rgb(119, 117, 117);
    padding: 2px;
}
.room-initial-chat-box-header{
    height: 50px;
    width: 50px;
    background-color: rgb(17, 82, 167);
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    place-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #fff;
    font-weight: 700;
    margin: auto 10px;
}
.join-meet-outer-box{
    position: relative;
}
.join-meet-outer{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}
@media screen and (max-width:660px){
    .main-chat-pro-sidebar{
        position: absolute;
        z-index: 1;
        width: 80%;
    }
    .main-chat-chats-box{
        width: 100%;
    }
    .top-header-side-options{
        width: 20%;
    }
    .no-room-selected{
        width: 100%;
    }
    .room-initial-chat-box-header{
        width: 40px;
        height: 40px;
    }
    .room-initial-chat{
        height: 10px;
        width: 10px;
        font-size: 8px;
    }
    .sidebar-room-chat
    {
        width: 100%;
    }
    .header-logo-box img{
        width: 35px;
        height: 35px;
        margin-right: 5px;
    }
    .header-logo-box h3{
        font-size: 1em;
    }
    .room-join-options-hover{
        height: 100%;
    }
    .chat-user{
        width: 100%;
    }
    .room-options-chat{
        width: 25%;
    }
}
